'use client'
import "./globals.scss";
import {useEffect} from "react";
import { useRouter } from 'next/router';
import NextTopLoader from 'nextjs-toploader';
import { GoogleTagManager, GoogleAnalytics } from '@next/third-parties/google'
import Header from "@/components/header"
import Footer from "@/components/footer"
import {Toaster} from "@/components/toaster"
import {GTM_ID, gtmPageView} from "@/lib/gtm";
import MicrosoftClarity from "@/lib/clarity";

export default function RootLayout({
                                       children,
                                   }: Readonly<{
    children: React.ReactNode;
}>) {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            gtmPageView();
        };
    }, [router.events]);

    return (
        <>
            <NextTopLoader
                color="#7D1DDE"
                initialPosition={0.08}
                crawlSpeed={200}
                height={4}
                crawl={true}
                showSpinner={false}
                easing="ease"
                speed={200}
                shadow="0px 4px 40px 0px rgba(125,29,222,1)"
                template='<div class="bar" role="bar"><div class="peg"></div></div>'
                zIndex={1600}
                showAtBottom={false}
            />
            <Header/>
            {children}
            <GoogleTagManager gtmId={GTM_ID} />
            <GoogleAnalytics gaId="G-0WEG0EQ3TG" />
            <MicrosoftClarity />
            <Footer/>
            <Toaster/>
        </>
    );
}
