import Image from "next/image";
import Link from 'next/link';

const Header: React.FC = () => {
    return (
        <header className="bg-darker pt-2 pb-3 px-8 flex justify-start lg:justify-between items-center sticky top-0 z-[100] border-b border-[#ffffff29]">
            <div className="flex items-center">
                <Link href="/">
                    <Image src="/images/logo.svg" alt="Logo" width={144} height={38}/>
                </Link>
                <nav>
                    <ul className="flex items-center">
                        <li>
                            <Link href="/mentors">
                                <button className="text-sm font-semibold py-2.5 pl-6 text-white">
                                    Mentors
                                </button>
                            </Link>
                        </li>
                        <li>
                            <Link href="/about-us">
                                <button className="text-sm font-semibold py-2.5 pl-6 text-white">
                                    About us
                                </button>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="hidden lg:block">
                <Link href="/jobs">
                    <button className="text-base font-semibold py-2.5 px-4 text-white border border-black mr-4">
                        Explore Jobs
                    </button>
                </Link>
                <Link href="/mentors">
                    <button className="text-base font-semibold py-2.5 px-4 text-white border border-white">
                        Request a session
                    </button>
                </Link>
            </div>
        </header>
    );
};

export default Header;
