import React from 'react';
import Link from 'next/link';
import Image from "next/image"; // Import the Link component

const Footer: React.FC = () => {
    const year = new Date().getFullYear();

    return (
        <footer className="py-12 px-8 lg:px-20 text-left bg-black">
            <div className="flex flex-col gap-11 lg:gap-0 lg:flex-row items-start justify-between lg:pr-96">
                <ul>
                    <li className="mb-4">
                        <Link href="/mentors" className="font-semibold text-sm text-white-68">Mentors</Link>
                    </li>
                    <li className="mb-4">
                        <Link href="/mentors" className="font-semibold text-sm text-white-68">
                            Mentorship for a team
                        </Link>
                    </li>
                    <li>
                        <Link href="/" className="font-semibold text-sm text-white-68">Become a mentor</Link>
                    </li>
                </ul>
                <ul>
                    <li className="mb-4">
                        <Link href="/about-us" className="font-semibold text-sm text-white-68">About us</Link>
                    </li>
                    <li className="mb-4">
                        <Link href="/#customer-stories" className="font-semibold text-sm text-white-68">Customer stories</Link>
                    </li>
                    <li className="mb-4">
                        <Link href="/#faqs" className="font-semibold text-sm text-white-68">FAQs</Link>
                    </li>
                    <li className="mb-4">
                        <Link href="/terms-of-service" className="font-semibold text-sm text-white-68">Terms of service</Link>
                    </li>
                    <li>
                        <Link href="/privacy-policy" className="font-semibold text-sm text-white-68">Privacy Policy</Link>
                    </li>
                </ul>
                <figure>
                    <figcaption>Follow us</figcaption>
                    <ul className="flex items-start gap-4 mt-4">
                        <li>
                            <a href="https://twitter.com/expatpie" target="_blank">
                                <Image src="/icons/twitter.svg" alt="x" height={24} width={24}/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/expatpie" target="_blank">
                                <Image src="/icons/instagram.svg" alt="instagram" height={24} width={24}/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/expatpie">
                                <Image src="/icons/linkedin.svg" alt="linkedin" height={24} width={24}/>
                            </a>
                        </li>
                    </ul>
                </figure>
            </div>
            <div className="border-t border-white-12 mt-10 pt-10">
                <p className="text-white-30 text-sm	font-normal">&copy; {year} ExpatPie. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
