import React from "react"
import RootLayout from "@/app/layout"
import {Barlow_Condensed, Inter} from "next/font/google";

interface AppProps {
    Component: React.ComponentType<any>; // The page component to be rendered
    pageProps: Record<string, any>; // Props passed to the page component
}

const inter = Inter({ subsets: ["latin"], variable: '--font-inter', });
const barlowCondensed = Barlow_Condensed({
    weight: ['400', '500','600', '700', '800', '900'],
    style: ['normal', 'italic'],
    subsets: ['latin'],
    display: 'swap',
    variable: '--font-barlowCondensed',
})

const _app: React.FC<AppProps> = ({ Component, pageProps }) => {
    return (
        <main className={`${barlowCondensed.variable} ${inter.variable} font-inter`}>
            <RootLayout>
                <Component {...pageProps} />
            </RootLayout>
        </main>
    );
}

export default _app;